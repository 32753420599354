<template>
  <div>
    <p class="mb-6">Inventario Actual</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <select-puesto
              v-model="filtros.id_puesto"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
              :ind_principal="true"
              :storePuesto="true"
              :filtros="{id_reporte:40}"
            ></select-puesto>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectCategoria
              :outlined="true"
              :clearable="true"
              :dense="true"
              v-model="filtros.id_categoria"
              :add="false"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <SelectSubCatoria
              :outlined="true"
              :clearable="true"
              :dense="true"
              v-model="filtros.id_sub_categoria"
              :padre="filtros.id_categoria"
              :add="false"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-select
              item-value="id"
              item-text="text"
              dense
              outlined
              v-model="filtros.existencia"
              :items="itemsExistencia"
              filled
              label="Existencia"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="8">
            <v-text-field
              class="mt-0 pt-0"
              outlined
              dense
              label="Nombre/Descripcion/referencia"
              v-model="filtros.nombre"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn small :loading="cargando" :disabled="cargando" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <v-data-table
          :headers="dessertHeaders"
          :items="datos"
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="id_producto_ing"
          show-expand
          class="elevation-1"
        >
          <template #[`item.existencia_total`]="{ item }">
            {{ funciones.formatoNumeric(item.existencia_total) }} {{ item.um_unidad }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table
                :headers="HeadersProductos"
                :items="item.productos"
                :single-expand="true"
                :expanded.sync="expanded"
                item-key="id_producto"
                show-expand
                class="elevation-1"
                dense
                hide-default-footer
              >
              <template #[`item.existencia`]="{ item }">
                  {{ funciones.formatoNumeric(item.existencia) }} {{ item.um_unidad_genpr }}
                </template>
                <template #[`item.cantidad`]="{ item }">
                X {{ funciones.formatoNumeric(item.cantidad) }} {{ item.um_unidad_gen }}
                </template>                
                <template #[`item.existencia_total`]="{ item }">
                  {{ funciones.formatoNumeric(item.existencia_total) }} {{ item.um_unidad_gen }}
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-simple-table dense>
                      <thead>
                        <th class="text-left">Nombre Puesto</th>
                        <th class="text-right">Existencia</th>
                        <th class="text-right">Existencia Total Ing</th>
                      </thead>
                      <tbody>
                        <tr v-for="(row, inx) in item.puestos" :key="inx">
                          <td class="text-left">{{ row.nombre_puesto }}</td>
                          <td class="text-right">{{ funciones.formatoNumeric(row.existencia) }} {{ row.um_unidad_genpr }}</td>
                          <td class="text-right">{{ funciones.formatoNumeric(row.existencia_total) }}  {{ row.um_unidad_gen }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </td>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue'
import { onBeforeMount, ref } from '@vue/composition-api'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuestoMulti.vue' //'../../../archivo/puesto/components/SelectPuesto.vue';
import ProductoService from '@/api/servicios/ProductosService'
import moment from 'moment'
import store from '@/store'
import SelectCategoria from '@/views/sistema/inventario/maestro/categoria/componentes/Select.vue'
import SelectSubCatoria from '@/views/sistema/inventario/maestro/sub-categoria/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectProductoTipo from '@/views/sistema/inventario/maestro/producto-tipo/componentes/Select.vue'
import funciones from '@/funciones/funciones.js'

// demos

export default {
  props: {
    id_puesto: {
      type: String,
      default: -1,
    },
  },
  components: {
    TablaSimpleReporte,
    SelectPuesto,
    SelectCategoria,
    SelectSubCatoria,
    SelectAlmacen,
    SelectProductoTipo,
  },
  watch: {
    userCliente: function (old, news) {
      if (old != news) {
        this.filtros.id_sub_categoria = null
      }
    },
    'filtros.id_puesto': function (old, news) {
      if (old != news) {
        this.filtros.id_almacen = null
      }
    },
    id_puesto: function (old, news) {
      if (old != news) {
        this.filtros.id_puesto = old
      }
    },
  },
  setup(props) {
    onBeforeMount(() => {
      if (props.id_puesto != -1) {
        filtros.value.id_puesto = props.id_puesto
      }
    })
    const nombreReporte = ref(`Inventario actual`)
    const itemsExistencia = [
      {
        id: 0,
        text: 'TODOS',
      },
      {
        id: 1,
        text: 'SOLO POSITIVOS',
      },
      {
        id: 2,
        text: 'SOLO NEGATIVOS',
      },
      {
        id: 3,
        text: 'SOLO NEGATIVOS Y POSITIVOS',
      },
      {
        id: 4,
        text: 'SOLO EN CERO',
      },
    ]

    const cabecera = ref([
      { text: 'Id', value: 'producto.id', format: { small: true } },

      { text: 'Ref', value: 'producto.referencia', format: { small: true }, visible: false },
      { text: 'Producto', value: 'producto.nombre', format: { small: true } },
      { text: 'Tipo', value: 'producto_tipo.descripcion', format: { small: true } },
      { text: 'Categoria', value: 'categoria.nombre', format: { small: true } },
      { text: 'Sub Categoria', value: 'sub_categoria.nombre', format: { small: true } },
      { text: 'Area', value: 'puesto.nombre', format: { small: true } },
      { text: 'Almacen', value: 'almacen.descripcion', format: { small: true } },
      { text: 'Medida', value: 'unidad_medida.descripcion', format: { small: true } },
      {
        text: 'Existencia',
        value: 'existencia',
        format: {
          small: true,

          color: {
            condicional: dato => {
              if (dato > 0) {
                return 'success--text'
              } else if (dato < 0) {
                return 'error--text'
              } else {
                return 'secondary--text'
              }
            },
          },
        },
      },

      { text: 'Costo', value: 'costo', format: { small: true } },
      { text: 'Total Costo', value: 'costo_total', format: { small: true } },
    ])

    const datos = ref([])
    const filtros = ref({
      id_categoria: null,
      id_sub_categoria: null,
      id_puesto: null,
      id_almacen: null,
      id_producto_tipo: null,
      existencia: 0,
      nombre: '',
    })

    const cargando = ref(false)

    const buscarDatos = () => {
      cargando.value = true
      ProductoService.rptInventarioActualIngAgrupado({ ...filtros.value, id_puesto: filtros.value.id_puesto.join(',') })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
            nombreReporte.value = `Reporte Inventario Actual`

            if (datos.value.length == 0) {
              store.commit('setAlert', {
                message: 'No hubo resultado a la busqueda',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'waring',
            })
          }
        })
        .catch(err => {})
        .finally(() => {
          cargando.value = false
        })
    }

    return {
      cabecera,
      datos,
      buscarDatos,
      cargando,
      nombreReporte,
      filtros,
      itemsExistencia,
      dessertHeaders: [
        { text: 'Id Ing', value: 'id_producto_ing' },
        { text: 'Producto', value: 'nombre_ingrediente' }, 
        { text: 'Existencia Total', value: 'existencia_total' },
      ],
      HeadersProductos: [
        { text: 'Id', value: 'id_producto' },
        { text: 'Producto', value: 'nombre_producto' },
        { text: 'Existencia', value: 'existencia' },
        { text: 'Cant-Ing', value: 'cantidad' },
        { text: 'existencia Total', value: 'existencia_total' },
      ],
      funciones,
    }
  },
}
</script>
